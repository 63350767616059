import React from 'react';
import Rating from '@mui/material/Rating';
import FullyOnline from '../../../assets/DlpFullyOnline.svg';
import DigitalAccountant from '../../../assets/DlpDigitalAccountant.svg';
import DlpMUIProfileImageDefault from '../../../assets/DlpMUIProfileImageDefault.svg';
import buildings from '../../../../../images/icons/buildings.svg';
import StyledButton from '../../../../../shared/components/styledButton/styledButton';
import i18n from '../../../../../utils/i18n';
import { ApprovedProviderBadge } from './ApprovedProviderBadge';
import { ProviderExperienceCards } from './ProviderExperienceCards';

const maxProviderNameLength = 40;

const CardContentWeb = ({ data, setOpenForward, ...props }) => {


	function calculateAccountLifetime(dateTime) {
		const inputDate = new Date(dateTime);
		const currentDate = new Date();

		if (isNaN(inputDate)) {
			throw new Error("Invalid date format");
		}

		const yearsDiff = currentDate.getFullYear() - inputDate.getFullYear();
		const monthsDiff = currentDate.getMonth() - inputDate.getMonth();
		const totalMonths = yearsDiff * 12 + monthsDiff;

		if (totalMonths === 0) return;

		if (totalMonths < 12) {
			return `${totalMonths} hónapja`;
		} else {
			const years = Math.floor(totalMonths / 12);
			return `${years} éve`;
		}
	}

	function maybeTruncateProviderName(providerName) {
		if (providerName.length > maxProviderNameLength) {
			return providerName.substring(0, maxProviderNameLength).concat("...");
		}
		return providerName;
	}

	//console.log(data, 'PROVIDERDATA');
	return (
		<>
			<div className="dlpProviderCardSection">
				<div className="dlpProviderCardHeader">
					<img
						src={data.providerImage || DlpMUIProfileImageDefault}
						alt="profile"
						style={{
							width: '48px',
							height: '48px',
							position: 'relative',
							objectFit: 'cover',
							objectPosition: 'top',
							borderRadius: '50%',
						}}
					/>
					<div className="dlpProviderCardTextContainer">
						<span className="dlpBold16" title={data.providerName}>
							{maybeTruncateProviderName(data.providerName)}
						</span>
						<p style={{ fontSize: '14px' }}>
							<Rating
								name="half-rating-read"
								value={data.reviewAvg}
								precision={0.1}
								readOnly
								size="small"
							/>
							{data.reviewNr > 0 && (
								<>
									{data.reviewAvg} ({data.reviewNr})
								</>
							)}
						</p>
						<p className="dlpText12">
							<img src={buildings} alt="buildings" />{' '}
							{data.packageSize === ''
								? i18n.t('general.accountant')
								: data.packageSize === 'entrepreneur'
									? i18n.t('provider.entrepreneuracc')
									: data.packageSize === 'small_office'
										? i18n.t('provider.small_acc_office')
										: i18n.t('provider.large_acc_office')}
						</p>
					</div>
					< ApprovedProviderBadge packageType={data.packageType} />
				</div>
			</div>
			< ProviderExperienceCards providerExperience={data.providerExperience} maxNumberOfCards={4} />

			<hr style={{ margin: '6px 0px !important' }} />
			<div className="dlpProviderCardBottom">
				<div>
					{calculateAccountLifetime(data.startDate) &&
						(<>
							<img src={FullyOnline} alt="fully online" className="fullyOnline" />{' '}
							<span className="dlpText12">{calculateAccountLifetime(data.startDate)} Bookkeepie könyvelő</span>
						</>)
					}
					{data.providerBadge !== 'Nem minősített' && (
						<>
							<br />
							<img
								src={DigitalAccountant}
								alt="digital accountant"
								className="digitalAccountant"
							/>{' '}
							<span className="dlpText12">
								{i18n.t('general.digitalaccountant')}
							</span>
						</>
					)}
				</div>

				<StyledButton
					onClick={(e) => {
						setOpenForward(true);
						e.stopPropagation();
					}}
					color={'NewReqBtn'}>
					{i18n.t('providerdashboard.requestcapital')}
				</StyledButton>
			</div>
		</>
	);
};

export default CardContentWeb;
