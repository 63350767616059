

export function ProviderExperienceCards({providerExperience, maxNumberOfCards}) {

    const industryLabels = {
		construction: 'Építőipar',
		engineer: 'Mérnök',
		vehicle: 'Gépjármű Ipar',
		manufacturing: 'Gyártás és Feldolgozó Ipar',
		IT: 'Informatika',
		realestate: 'Ingatlankereskedelem',
		retail: 'Kiskereskedelem',
		wholesale: 'Nagykereskedelem',
		agriculture: 'Mező- és erdőgazdálkodás, halászat',
		education: 'Oktatás, Kutatás',
		art: 'Művészet',
		medicine: 'Egészségügy',
		beauty: 'Szépségipar',
		finance: 'Pénzügy, biztosítás',
		travel: 'Szállás, Vendéglátás',
		logistics: 'Szállítás, raktározás',
		courier: 'Futár',
		otherservices: 'Egyéb',
		etrade: 'E-kereskedelem',
		startup: 'Startup',
		lawyer: 'Ügyvédi iroda',
		sport: 'Sport',
		other: 'Egyéb',
		cantdecide: 'Nem tudok ezek közül választani',
		MK: 'Magyar Könyvelők',
		OET: 'Országos Egyesülete Tag',
		certified: 'Mérlegképes',
		accountant: 'könyvelő',
	};

    const lengthOfProviderExperience = providerExperience.length;

    return (
        <div className="dlpExpContainer">
        {lengthOfProviderExperience > 0 &&
            providerExperience.map((expItem, idx) => {
                if (idx < maxNumberOfCards) {
                    return (<span key={idx} className="dlpExpItem"> {industryLabels[expItem]} </span>);
                } else return null;
            })}
        {lengthOfProviderExperience > maxNumberOfCards && (
            <span className="dlpExpItem"> {' '} + {lengthOfProviderExperience - maxNumberOfCards} {' '} </span>
        )}
    </div>
    )
}