import React, { useCallback } from 'react';
import { vatFilterInput, vatInput } from '../data/searchFlowText';
import { useSelector } from 'react-redux';
import Navigation from '../components/navigation/navigation';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';
import RadioInput from '../components/searchInputs/radioInput';

const Vat = ({ ...props }) => {
	const companyStore = useSelector((state) => state.company_store);

	const handleNext = useCallback(
		(inc) => {
			props.handleNext(inc);
		},
		[props]
	);

	const handleBack = useCallback(
		(decr) => {
			props.handleBack(decr);
		},
		[props]
	);

	//console.log(companyStore.vatFilter, 'FILTER');

	return (
		<div className="stepContainer">
			<div className="SFInput">
				<p className="SFSubTitle">
					<span className="SFSubTitle">
						{i18n.t('searchflow.pricingtext6a')}
					</span>{' '}
					<span className="questionTracker">3/8</span>
				</p>
				<RadioInput storeID="vatFilter" dataText={vatFilterInput()} />

				{companyStore.vatFilter === 'true' ? (
					<>
						<p className="SFSubTitle">{i18n.t('searchflow.pricingtext6')}</p>
						<RadioInput storeID="vatPayment" dataText={vatInput()} />
					</>
				) : (
					<></>
				)}
			</div>
			<Navigation
				step={3}
				handleBack={() => {
					handleBack(1);
				}}
				handleNext={() => {
					handleNext();
				}}
				openHelpForm={() => props.openHelpForm()}
				disabledProp={companyStore.vatFilter !== '' ? false : true}
			/>
		</div>
	);
};

export default withTranslation()(Vat);
