import i18n from "../../../../../utils/i18n";
import ApprovedProvCard from '../../../assets/DlpApprovedProvCard.svg';

export function ApprovedProviderBadge({packageType}) {
    return (
        packageType === 'premium' && (
            <div className="dlpApprovedProv">
                <span className='dlpApprovedText'>{i18n.t('general.certifiedaccountantshort')}</span>{' '}
                <img
                    className="dlpApprovedProvCard"
                    alt="approved prov card"
                    src={ApprovedProvCard}
                />
            </div>
        )
    )
}