import React, { useCallback, useEffect } from 'react';
import {
	companyTypeInput,
	companyTypeFoundingInput,
} from '../data/searchFlowText';
import { useSelector, useDispatch } from 'react-redux';
import Navigation from '../components/navigation/navigation';
import { useNavigate } from 'react-router-dom';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';
import axios from '../../utils/axiosCompanyProxy';
import ACTIONS from '../../../redux/action';
import RadioInput from '../components/searchInputs/radioInput';

const CompanyType = ({ ...props }) => {
	const [companyError, setCompanyError] = React.useState(false);
	const companyStore = useSelector((state) => state.company_store);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	// const handleNext = useCallback(
	//   (inc) => {
	//     props.handleNext(inc);
	//   },
	//   [props]
	// );
	const handleBack = useCallback(
		(decr) => {
			props.handleBack(decr);
		},
		[props]
	);

	const handleNext = useCallback(
		(e) => {
			// if (
			//   companyStore.companytype === 'singleprimary' ||
			//   companyStore.companytype === 'kiva' ||
			//   companyStore.companytype === 'singlesecondary' ||
			//   companyStore.companytype === 'vszja'
			// ) {
			//   dispatch(
			//     ACTIONS.addCompanyInfo({
			//       id: 'activeStep',
			//       data: 1,
			//       stateObject: 'company_store',
			//     })
			//   );
			//   navigate('/company/request/reg');
			// } else {
			props.handleNext(e);
			// }
		},
		[props]
		//[props, companyStore, navigate, dispatch]
	);

	const handleCompanyTypeChange = (value) => {
		dispatch(
			ACTIONS.addCompanyInfo({
				id: 'companytype',
				data: value,
				stateObject: 'company_store',
			})
		);
	};

	const dataLayerUpdate = () => {
		window.dataLayer.push({
			event: 'sf_step_reg_request_sent',
			event_category: 'regflow',
		});
	};

	const requestCallBack = (companyData) => {
		let data = Object.assign({}, companyData);
		//console.log(data, 'ASPO REQ DATA');

		axios
			.post(`/api/aspreq/requestcallback/new`, data, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then((response) => {
				console.log('AXIOS RESPONSE: ', response);
				const reqID = response.data.result._id;
				if (reqID) {
					dispatch(
						ACTIONS.addCompanyInfo({
							id: 'reqID',
							data: reqID,
							stateObject: 'company_store',
						})
					);
				}
			})
			.catch((error) => {
				console.log('AXIOS ERROR: ', error.response?.data || error.message);
			});
	};

	useEffect(() => {
		dispatch(
			ACTIONS.addCompanyInfo({
				id: 'reqFromBupa',
				data: false,
				stateObject: 'company_store',
			})
		);
		dispatch(
			ACTIONS.addCompanyInfo({
				id: 'callbacksource',
				data: 'regkftrequest',
				stateObject: 'company_store',
			})
		);
		dispatch(
			ACTIONS.addCompanyInfo({
				id: 'leadtype',
				data: 'REG',
				stateObject: 'company_store',
			})
		);
		dispatch(
			ACTIONS.addCompanyInfo({
				id: 'isCallBackReq',
				data: false,
				stateObject: 'company_store',
			})
		);
	}, [dispatch]);

	return (
		<div className="stepContainer">
			<div className="SFInput">
				<p className="SFSubTitle">
					<span className="SFSubTitle">{i18n.t('company.companyform')}</span>{' '}
					<span className="questionTracker">1/8</span>
				</p>
				{companyStore.legalentityfounding ? (
					<RadioInput
						storeID="companytype"
						dataText={companyTypeFoundingInput()}
						onChange={handleCompanyTypeChange}
					/>
				) : (
					<RadioInput
						storeID="companytype"
						dataText={companyTypeInput()}
						onChange={handleCompanyTypeChange}
					/>
				)}
				{/* {companyError ? (
          <span style={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>
            {i18n.t('searchflow.choosetocontinue')}
          </span>
        ) : null} */}
			</div>
			<Navigation
				step={1}
				handleBack={() => handleBack()}
				handleNext={() => {
					// if (
					//   companyStore.companytype === 'idk' &&
					//   companyStore.legalentityfounding
					// ) {
					//   companyStore.activeStep = 1;
					//   navigate('/company/request/vaf');
					// } else {
					// companyStore.companytype === 'singleprimary' ||
					// companyStore.companytype === 'kiva' ||
					// companyStore.companytype === 'singlesecondary' ||
					// companyStore.companytype === 'vszja'
					//   ? handleNext(2)
					//   : handleNext(1);
					// }
					if (companyStore.companytype === 'entrepreneur') {
						handleNext(1);
					} else if (
						companyStore.companytype === 'kft' &&
						companyStore.newlyfounded
					) {
						dataLayerUpdate();
						requestCallBack(companyStore);
						dispatch(
							ACTIONS.addCompanyInfo({
								id: 'submitted',
								data: true,
								stateObject: 'company_store',
							})
						);
						window.location.href =
							'https://t40tz85n2uw.typeform.com/ekftkerdoiv';
					} else {
						handleNext(1);
					}
				}}
				openHelpForm={() => props.openHelpForm()}
				disabledProp={companyStore.companytype === '' ? true : false}
			/>
		</div>
	);
};

export default withTranslation()(CompanyType);
